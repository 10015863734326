<template>
	<!-- <v-app style="background-color:#003333 !important"> -->
	<v-app class="wallpaper">
		<!-- eslint-disable -->
		<v-row
			class="
				mx-auto
				my-auto
				d-flex
				justify-content-center
				bg-dark
				col-md-8
				bg-transperant
			"
		>
			<v-col cols="12" sm="6" md="5" class="my-auto mx-auto">
				<v-card
					:elevation="16"
					class="v-card-profile col-md-12 rounded-lg"
					flat
				>
					<span class="d-flex flex-row align-baseline ml-8"
						><img src="../assets/munjz.png" class="mr-2 hidden-sm-and-down" />
						<h1>
							<span
								class="hidden-sm-and-down"
								style="color: #2196f3; margin-left: 10px; font-size: 35px"
								>B2B Munjz</span
							>
						</h1></span
					>

					<span class="d-flex flex-row align-baseline ml-8 hidden-md-and-up">
						<img
							src="../assets/munjz.png"
							height="72"
							max-width="72"
							class="mr-2 hidden-md-and-up"
						/>
						<h1>
							<span
								class="hidden-md-and-up"
								style="color: #2196f3; margin-left: 10px; font-size: 35px"
								>B2B Munjz</span
							>
						</h1>
					</span>
					<v-card-text class="justify-content-center">
						<form
							class="
								text-center
								mx-auto
								justify-content-center
								align-content-center
							"
							@submit.prevent="login"
							@keydown="form.onKeydown($event)"
						>
							<v-text-field
								v-model="form.email"
								class="col-md-12"
								type="email"
								label="Email"
								prepend-inner-icon="mdi-account"
								required
								outlined
								dense
							/>
							<v-text-field
								v-model="form.password"
								class="col-md-12"
								type="password"
								label="Password"
								prepend-inner-icon="mdi-lock"
								required
								outlined
								dense
							/>
							<!-- <v-row class="col-md-10">
              <a>Forgot Password?</a> </v-row>
              <v-spacer></v-spacer> -->
							<span class="d-flex flex-column" justify="center">
								<v-btn
									text
									to="forgotPassword"
									style="text-decoration: underline"
									>Forgot Password ?</v-btn
								>
								<center>
									<v-btn
										color="primary"
										width="150"
										:loading="loading"
										class="mt-3 me-0"
										type="submit"
									>
										Login
									</v-btn>
								</center>
							</span>
						</form>
					</v-card-text>
				</v-card>
			</v-col>
			<v-snackbar
				v-model="snackbar"
				:bottom="true"
				:left="false"
				:color="color"
				:right="false"
				:timeout="timeout"
				:top="false"
				:vertical="false"
				class="justify-content-center"
			>
				<span
					style="
						margin-left: 20% !important;
						color: #003333 !important;
						font-size: 16px !important;
					"
					>{{ text }}</span
				>
				<!-- <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn> -->
			</v-snackbar>
		</v-row>
	</v-app>
</template>

<script>
/* eslint-disable */
import Vue from "vue";

import { Form, HasError, AlertError } from "vform";
Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);
export default {
	name: "Login",
	data: () => ({
		companyProfile: {},
		user_id: null,
		image: require("@/assets/b2b_icon-03.png"),
		loading: false,
		snackbar: false,
		y: "top",
		x: null,
		mode: "",
		timeout: 2000,
		text: "Hello, I'm a snackbar",
		color: "",
		form: new Form({
			email: "",
			password: "",
			remember: false,
		}),
	}),
	mounted() {
		localStorage.removeItem("signedin");
		localStorage.removeItem("userid");
		localStorage.removeItem("name");
		localStorage.removeItem("parent_id");
		localStorage.removeItem("email");
		localStorage.removeItem("type");
		localStorage.removeItem("token");
		localStorage.removeItem("current_user_permissions");
		localStorage.removeItem("partner_branches");
		localStorage.removeItem("role");
		localStorage.removeItem("companyName");
		localStorage.removeItem("companyLogo");
	},
	methods: {
		login() {
			// Submit the form via a POST requesthttps://munjz.info/api/v3/store/login
			this.loading = true;
			this.form
				.post("/api/v3/b2b/login")
				.then(({ data }) => {
					if (data.statusCode === 400) {
						this.text = data.msg;
						this.color = "white";
						this.snackbar = true;
					} else if (data.statusCode === 200) {
						this.user_id = data.user_id;
						this.getcompanyProfile(this.user_id, data.token);
						this.dispatch(data);
						// localStorage.setItem('signedin', true)
						// localStorage.setItem('userid', data.user_id)
						// localStorage.setItem('email', data.email)
						// router.push('/home/dashboard')
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {});
		},
		dispatch(data) {
			if (data.user_type === 3) {
				localStorage.setItem("signedin", true);
				localStorage.setItem("name", data.user_name);
				localStorage.setItem("role", data.role);
				localStorage.setItem("userid", data.user_id);
				localStorage.setItem("parent_id", data.parent_id);
				localStorage.setItem("email", data.email);
				localStorage.setItem("type", data.user_type);
				localStorage.setItem("credit_balance", data.credit_balance);
				localStorage.setItem(
					"current_user_permissions",
					JSON.stringify(data.partner_permissions)
				);
				localStorage.setItem(
					"partner_branches",
					JSON.stringify(data.partner_branches)
				);
				this.$router.push("/dashboard");
				setTimeout(() => {
					location.reload();
				}, 800);
			} else if (data.user_type === 4) {
				localStorage.setItem("signedin", true);
				localStorage.setItem("userid", data.user_id);
				localStorage.setItem("role", data.role);
				localStorage.setItem("parent_id", data.parent_id);
				localStorage.setItem("name", data.user_name);
				localStorage.setItem("type", data.user_type);
				localStorage.setItem("email", data.email);
				localStorage.setItem("token", data.token);
				localStorage.setItem("credit_balance", data.credit_balance);

				localStorage.setItem(
					"current_user_permissions",
					JSON.stringify(data.partner_permissions)
				);
				localStorage.setItem(
					"partner_branches",
					JSON.stringify(data.partner_branches)
				);
				//New

				let userPermissionsList = [];
				let landingPageWithAccess = [];
				let hasAccessToDashboard = [];
				axios
					.get("/api/v3/b2b/permissions/get-permissions-by-user-id", {
						headers: { "X-Authorization": data.token },
						params: { user_id: data.user_id },
					})
					.then((response) => {
						if (response.data.status == 200) {
							userPermissionsList = response.data.admin_permissions;
							let loggedinUserpermissions = userPermissionsList.map(
								(permission) => permission.name
							);

							this.$store.dispatch(
								"setUserPermissions",
								loggedinUserpermissions
							);

							hasAccessToDashboard = userPermissionsList.filter((permission) =>
								permission.name.includes("View Dashboard Page")
							);

							landingPageWithAccess = userPermissionsList.filter((permission) =>
								permission.name.includes("Page")
							);

							if (hasAccessToDashboard.length != 0) {
								this.$router.push("dashboard");
								setTimeout(() => {
									location.reload();
									location.reload();
								}, 800);
							} else if (landingPageWithAccess.length != 0) {
								this.$router.push(landingPageWithAccess[0].group);
								setTimeout(() => {
									location.reload();
									location.reload();
								}, 800);
							}
						}
					});

				this.$router.push("/dashboard");
				setTimeout(() => {
					location.reload();
					location.reload();
				}, 800);
			}
		},
		getcompanyProfile(userID, token) {
			axios
				.get("api/v3/b2b/partner-details", {
					headers: { "X-Authorization": token },
					params: {
						user_id: userID,
					},
				})
				.then((res) => {
					this.companyProfile = res.data;

					localStorage.setItem(
						"companyName",
						this.companyProfile.partner_detail.company_name
					);

					localStorage.setItem("companyLogo", this.companyProfile.company_logo);
				});
		},
	},
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2&display=swap");
.wallpaper {
	/* position: fixed; top: 0; left: 0; */
	background-image: url("../assets/15229.jpg") !important;
	background-repeat: no-repeat !important;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
</style>
